import React from "react";
import ReactDOM from "react-dom";
import axios from "axios";

import Users from "./Users";
import User from "./User";
import Spinner from './Spinner'
import ErrorMessage from './ErrorMessage'
import UserModal from "./UserModal";

class UserPartial extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      users: [],
      isLoading: true,
      errorMessage: null,
      term: ""
    }
    this.getUsers = this.getUsers.bind(this);
    this.handleErrors = this.handleErrors.bind(this)
    this.getAutoCompleteResults = this.getAutoCompleteResults.bind(this)
    this.createUser = this.createUser.bind(this);
    this.clearErrors = this.clearErrors.bind(this)
  }
  componentDidMount() {
    this.getUsers();
  }
  getUsers() {
    axios
      .get("/api/v1/users")
      .then(response => {
        this.clearErrors()
        this.setState({ isLoading: true })
        const users = response.data;
        this.setState({ users });
        this.setState({ isLoading: false })
      })
      .catch(error => {
          this.setState({ isLoading: true })
          this.setState({
              errorMessage: {
                  message:
                      'There was an error loading your users...',
              },
          })
      })
  }
  getAutoCompleteResults(e){
    this.setState({
      term: e.target.value
    }, () => {
      axios
        .get("/api/v1/search_users?q=" + this.state.term)
        .then(response => {
          this.setState({ users: response.data.users})
        })
    });
  }
  createUser(user) {
       const users = [user, ...this.state.users];
       this.setState({ users });
     }
  addUserToState = (user) => {
    this.setState(prevState => ({
      users: [...prevState.users, user]
    }))
  }
   handleErrors(errorMessage) {
      this.setState({ errorMessage })
      }
   clearErrors() {
      this.setState({
         errorMessage: null,
           })
       }

       render() {
               return (
                   <>
                       {this.state.errorMessage && (
                           <ErrorMessage errorMessage={this.state.errorMessage} />
                       )}
                       {!this.state.isLoading && (
                           <>
                               <UserModal
                                    createUser={this.createUser}
                                    handleErrors={this.handleErrors}
                                    clearErrors={this.clearErrors}
                                    getUsers={this.getUsers}
                                />
                               <Users getAutoCompleteResults={
                                   this.getAutoCompleteResults}
                                   term={
                                       this.state.term
                                   }
                                   >
                                   {this.state.users.map(user => (
                                       <User
                                           key={user.id}
                                           user={user}
                                           getUsers={this.getUsers}
                                           handleErrors={this.handleErrors}
                                           clearErrors={this.clearErrors}
                                       />
                                   ))}
                               </Users>
                           </>
                       )}
                       {this.state.isLoading && <Spinner />}
                   </>
               )
           }
       }
document.addEventListener('turbolinks:load', () => {
    const app = document.getElementById('user-partial')
    app && ReactDOM.render(<UserPartial/>, app)
})
