import React from "react";
import PropTypes from "prop-types";
import {
  Modal,
  ModalHeader,
  ModalBody,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink
} from "reactstrap";
import MaskedInput from "react-text-mask";
import classnames from "classnames";
import axios from "axios";
import setAxiosHeaders from "./AxiosHeaders";
import Preinscription from "./Preinscription";
import Button from "@material-ui/core/Button";
import moment from "moment";
import DatePicker from "react-datepicker";
import TextField from "@material-ui/core/TextField";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";

class PreinscriptionModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      startDate: new Date(),
      activeTab: 1
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.firstNameRef = React.createRef();
    this.lastNameRef = React.createRef();
    this.phone1Ref = React.createRef();
    this.phone2Ref = React.createRef();
    this.phone3Ref= React.createRef();
    this.phone4Ref= React.createRef();
    this.emailRef = React.createRef();
    this.birthdayRef = React.createRef();
    this.birthplaceRef= React.createRef();
    this.nom_prenom_mereRef=React.createRef;
    this.nom_pereRef=React.createRef;
    this.nom_lyceeRef=React.createRef;
    this.nom_arabicRef=React.createRef;
    this.prenom_arabicRef=React.createRef;
    this.commune_arabicRef=React.createRef;
    this.wilaya_arabicRef=React.createRef;
    this.adresse_arabicRef=React.createRef;
    this.nom_lycee_arabicRef=React.createRef;
    this.nom_prenom_mere_arabicRef=React.createRef;
    this.nom_pere_arabicRef=React.createRef;
    this.lieu_naissance_arabicRef=React.createRef;
    this.daira_arabicRef= React.createRef();
    this.dairaRef= React.createRef();
    this.cinRef= React.createRef();
    this.lieu_lycee_arabicRef= React.createRef();
    this.lieu_lyceeRef= React.createRef();
    this.fonction_parentRef= React.createRef();
    this.fonction_parent_arabicRef= React.createRef();
    this.specialiteRef= React.createRef();
    this.specialite_arabicRef= React.createRef();
    this.niveauRef= React.createRef();
    this.niveau_arabicRef= React.createRef();
    this.maladiesRef= React.createRef();
    this.maladies_arabicRef= React.createRef();
    this.polycliniqueRef= React.createRef();
    this.polyclinique_arabicRef= React.createRef();
    this.chuRef= React.createRef();
    this.chu_arabicRef= React.createRef();
    this.epspRef= React.createRef();
    this.epsp_arabicRef= React.createRef();
    this.ephRef= React.createRef();
    this.eph_arabicRef= React.createRef();
    this.lieu_polycliniqueRef= React.createRef();
    this.lieu_polyclinique_arabicRef= React.createRef();
    this.lieu_chuRef= React.createRef();
    this.lieu_chu_arabicRef= React.createRef();
    this.lieu_epspRef= React.createRef();
    this.lieu_epsp_arabicRef= React.createRef();
    this.lieu_ephRef= React.createRef();
    this.lieu_eph_arabicRef= React.createRef();

  }

  handleChange = date => {
    this.setState({
      startDate: date
    });
    this.birthdayRef = date;
  };
  toggleB = tab => {
    if (this.state.activeTab !== tab)
      this.setState({
        activeTab: tab
      });
  };
  toggle = () => {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  };
  handleSubmit(e) {
    e.preventDefault();
    axios
      .post("/api/v1/preinscriptions", {
        preinscription: {
          first_name: this.firstNameRef.value,
          last_name: this.lastNameRef.value,
          phone1: this.phone1Ref.value,
          phone2: this.phone2Ref.value,
          phone3: this.phone3Ref.value,
          phone4: this.phone4Ref.value,
          cin: this.cinRef.value,
          email: this.emailRef.value,
          birthplace: this.birthplaceRef.value,
          nom_pere: this.nom_pereRef.value,
          nom_prenom_mere: this.nom_prenom_mereRef.value,
          nom_lycee: this.nom_lyceeRef.value,
          lieu_lycee: this.lieu_lyceeRef.value,
          adress: this.adressRef.value,
          city: this.cityRef.value,
          wilaya: this.wilayaRef.value,
          birthday: moment(this.birthdayRef).format("DD/MM/YYYY"),
          nom_arabic: this.nom_arabicRef.value,
          prenom_arabic: this.prenom_arabicRef.value,
          commune_arabic: this.commune_arabicRef.value,
          wilaya_arabic: this.wilaya_arabicRef.value,
          daira_arabic: this.daira_arabicRef.value,
          daira: this.dairaRef.value,
          adresse_arabic: this.adresse_arabicRef.value,
          nom_lycee_arabic: this.nom_lycee_arabicRef.value,
          lieu_lycee_arabic: this.lieu_lycee_arabicRef.value,
          nom_prenom_mere_arabic: this.nom_prenom_mere_arabicRef.value,
          nom_pere_arabic: this.nom_pere_arabicRef.value,
          lieu_naissance_arabic: this.lieu_naissance_arabicRef.value,
          fonction_parent: this.fonction_parentRef.value,
          fonction_parent_arabic: this.fonction_parent_arabicRef.value,
          specialite: this.specialiteRef.value,
          specialite_arabic: this.specialite_arabicRef.value,
          niveau: this.niveauRef.value,
          niveau_arabic: this.niveau_arabicRef.value,
          maladies: this.maladiesRef.value,
          maladies_arabic: this.maladies_arabicRef.value,
          polyclinique: this.polycliniqueRef.value,
          polyclinique_arabic: this.polyclinique_arabicRef.value,
          chu: this.chuRef.value,
          chu_arabic: this.chu_arabicRef.value,
          epsp: this.epspRef.value,
          epsp_arabic: this.epsp_arabicRef.value,
          eph: this.ephRef.value,
          eph_arabic: this.eph_arabicRef.value,
          lieu_polyclinique: this.lieu_polycliniqueRef.value,
          lieu_polyclinique_arabic: this.lieu_polyclinique_arabicRef.value,
          lieu_chu: this.lieu_chuRef.value,
          lieu_chu_arabic: this.lieu_chu_arabicRef.value,
          lieu_epsp: this.lieu_epspRef.value,
          lieu_epsp_arabic: this.lieu_epsp_arabicRef.value,
          lieu_eph: this.lieu_ephRef.value,
          lieu_eph_arabic: this.lieu_eph_arabicRef.value,
          complete: true,
          invited: false
        }
      })
      .then(response => {
        const preinscription = response.data;
        toastr.success("Pré-inscription créée!");
        this.props.createPreinscription(preinscription);
      })
      .catch(error => {
        console.log(error);
      });
    e.target.reset();
  }

  render() {
    const closeBtn = (
      <button className="close" onClick={this.toggle}>
        &times;
      </button>
    );
    const label = this.props.buttonLabel;
    let button = "";
    let title = "Ajouter une Pré-inscription";
    button = (
      <Button
        variant="contained"
        color="primary"
        onClick={this.toggle}
        startIcon={<AddCircleOutlineOutlinedIcon />}
      >
        Ajouter une Preinscription
      </Button>
    );
    return (
      <div>
        {button}
        <Modal
          size="xl"
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggle} close={closeBtn}>
            {title}
          </ModalHeader>
          <ModalBody>
            <form onSubmit={this.handleSubmit} className="my-3">
              <div>
                <Nav tabs>
                <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === "3"
                      })}
                      onClick={() => {
                        this.toggleB("3");
                      }}
                    >
                      Contacts
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === "1"
                      })}
                      onClick={() => {
                        this.toggleB("1");
                      }}
                    >
                      Informations personnelles
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === "2"
                      })}
                      onClick={() => {
                        this.toggleB("2");
                      }}
                    >
                      المعلومات الشخصية
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                  <TabPane tabId="1">
                    <div className="form-row">
                      <div className="form-group col-sm col-md col-lg">
                        <TextField
                          autoFocus={true}
                          inputRef={el => (this.firstNameRef = el)}
                          placeholder="Prénom"
                          id="first_name"
                        />
                      </div>
                      <div className="form-group col-sm col-md col-lg">
                        <TextField
                          autoFocus={true}
                          inputRef={el => (this.lastNameRef = el)}
                          placeholder="Nom"
                          className="form-control"
                          id="last_name"
                        />
                      </div>
                      <div className="form-group col-sm col-md col-lg">
                        <TextField
                          autoFocus={true}
                          inputRef={el => (this.birthplaceRef = el)}
                          placeholder="Lieu de naissance"
                          className="form-control"
                          id="birthplace"
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-sm col-md col-lg">
                        <TextField
                          autoFocus={true}
                          inputRef={el => (this.nom_pereRef = el)}
                          placeholder="Prénom du père"
                          className="form-control"
                          id="nom_pere"
                        />
                      </div>
                      <div className="form-group col-sm col-md col-lg">
                        <TextField
                          autoFocus={true}
                          inputRef={el => (this.nom_prenom_mereRef = el)}
                          placeholder="Nom et prénom de la mère"
                          className="form-control"
                          id="nom_prenom_mere"
                        />
                      </div>
                    </div>
                    <div className="form-row">
                    <div className="form-group col-sm col-md col-lg">
                        <TextField
                          autoFocus={true}
                          inputRef={el => (this.nom_lyceeRef = el)}
                          placeholder="Nom du lycée"
                          className="form-control"
                          id="nom_lycee"
                        />
                      </div>
                      <div className="form-group col-sm col-md col-lg">
                        <TextField
                          autoFocus={true}
                          inputRef={el => (this.lieu_lyceeRef = el)}
                          placeholder="Localité du lycée"
                          className="form-control"
                          id="lieu_lycee"
                        />
                      </div>
                     </div>
                    <div className="form-row">
                      <div className="form-group col-sm col-md col-lg">
                        <TextField
                          autoFocus={true}
                          inputRef={el => (this.cityRef = el)}
                          placeholder="Commune"
                          className="form-control"
                          id="city"
                        />
                      </div>
                      <div className="form-group col-sm col-md col-lg">
                        <TextField
                          autoFocus={true}
                          inputRef={el => (this.dairaRef = el)}
                          placeholder="Daira"
                          className="form-control"
                          id="daira"
                        />
                      </div>
                      <div className="form-group col-sm col-md col-lg">
                        <TextField
                          autoFocus={true}
                          inputRef={el => (this.wilayaRef = el)}
                          placeholder="Wilaya"
                          className="form-control"
                          id="wilaya"
                        />
                      </div>
                     </div>
                     <div className="form-row">
                    <div className="form-group col-sm col-md col-lg">
                        <TextField
                          autoFocus={true}
                          inputRef={el => (this.fonction_parentRef = el)}
                          placeholder="Fonction du père"
                          className="form-control"
                          id="fonction_parent"
                        />
                      </div>
                      <div className="form-group col-sm col-md col-lg">
                        <TextField
                          autoFocus={true}
                          inputRef={el => (this.maladiesRef = el)}
                          placeholder="Etat de santé"
                          className="form-control"
                          id="maladies"
                        />
                      </div>
                      </div>
                     
                     <div className="form-row">
                    <div className="form-group col-sm col-md col-lg">
                        <TextField
                          autoFocus={true}
                          inputRef={el => (this.adressRef = el)}
                          placeholder="Adresse"
                          className="form-control"
                          id="address"
                        />
                      </div>
                      </div>
                      <div className="form-row">
                      <div className="form-group col-sm col-md col-lg">
                        <TextField
                          autoFocus={true}
                          inputRef={el => (this.specialiteRef = el)}
                          placeholder="Spécialité"
                          className="form-control"
                          id="specialite"
                        />
                      </div>
                      <div className="form-group col-sm col-md col-lg">
                        <TextField
                          autoFocus={true}
                          inputRef={el => (this.niveauRef = el)}
                          placeholder="Niveau"
                          className="form-control"
                          id="niveau"
                        />
                      </div>
                      </div>
                      <div className="form-row">
                      <div className="form-group col-sm col-md col-lg">
                        <TextField
                          autoFocus={true}
                          inputRef={el => (this.polycliniqueRef = el)}
                          placeholder="Polyclinique"
                          className="form-control"
                          id="polyclinique"
                        />
                      </div>
                      <div className="form-group col-sm col-md col-lg">
                        <TextField
                          autoFocus={true}
                          inputRef={el => (this.lieu_polycliniqueRef = el)}
                          placeholder="Localité du polyclinique"
                          className="form-control"
                          id="lieu_polyclinique"
                        />
                      </div>
                      </div>
                      <div className="form-row">
                      <div className="form-group col-sm col-md col-lg">
                        <TextField
                          autoFocus={true}
                          inputRef={el => (this.chuRef = el)}
                          placeholder="CENTRE HOSPITALO-UNIVERSITAIRE CHU"
                          className="form-control"
                          id="chu"
                        />
                      </div>
                      <div className="form-group col-sm col-md col-lg">
                        <TextField
                          autoFocus={true}
                          inputRef={el => (this.lieu_chuRef = el)}
                          placeholder="Localité du CENTRE HOSPITALO-UNIVERSITAIRE CHU"
                          className="form-control"
                          id="lieu_chu"
                        />
                      </div>
                      </div>
                      <div className="form-row">
                      <div className="form-group col-sm col-md col-lg">
                        <TextField
                          autoFocus={true}
                          inputRef={el => (this.epspRef = el)}
                          placeholder="Etablissements Publics de Santé de Proximité EPSP"
                          className="form-control"
                          id="epsp"
                        />
                      </div>
                      <div className="form-group col-sm col-md col-lg">
                        <TextField
                          autoFocus={true}
                          inputRef={el => (this.lieu_epspRef = el)}
                          placeholder="Localité de l'Etablissements Publics de Santé de Proximité EPSP"
                          className="form-control"
                          id="lieu_epsp"
                        />
                      </div>
                      </div>
                      <div className="form-row">
                      <div className="form-group col-sm col-md col-lg">
                        <TextField
                          autoFocus={true}
                          inputRef={el => (this.ephRef = el)}
                          placeholder="Etablissement Hospitalier publique EPH"
                          className="form-control"
                          id="eph"
                        />
                      </div>
                      <div className="form-group col-sm col-md col-lg">
                        <TextField
                          autoFocus={true}
                          inputRef={el => (this.lieu_ephRef = el)}
                          placeholder="Localité de l'Etablissement Hospitalier publique EPH"
                          className="form-control"
                          id="lieu_eph"
                        />
                      </div>
                      </div>
                  </TabPane>
                  <TabPane tabId="2">
                    <div className="form-row">
                      <div className="form-group col-sm col-md col-lg">
                        <TextField
                          autoFocus={true}
                          inputRef={el => (this.nom_arabicRef = el)}
                          placeholder="الاسم"
                          id="nom_arabic"
                        />
                      </div>
                      <div className="form-group col-sm col-md col-lg">
                        <TextField
                          autoFocus={true}
                          inputRef={el => (this.prenom_arabicRef = el)}
                          placeholder="اللقب"
                          className="form-control"
                          id="prenom_arabic"
                        />
                      </div>
                      <div className="form-group col-sm col-md col-lg">
                        <TextField
                          autoFocus={true}
                          inputRef={el => (this.lieu_naissance_arabicRef = el)}
                          placeholder="مكان الميلاد"
                          className="form-control"
                          id="lieu_naissance_arabic"
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-sm col-md col-lg">
                        <TextField
                          autoFocus={true}
                          inputRef={el => (this.nom_pere_arabicRef = el)}
                          placeholder="إسم الاب"
                          className="form-control"
                          id="nom_pere_arabic"
                        />
                      </div>
                      <div className="form-group col-sm col-md col-lg">
                        <TextField
                          autoFocus={true}
                          inputRef={el => (this.nom_prenom_mere_arabicRef = el)}
                          placeholder="إسم و لقب الأم"
                          className="form-control"
                          id="nom_prenom_mere_arabic"
                        />
                      </div>
                    </div>
                    <div className="form-row">
                    <div className="form-group col-sm col-md col-lg">
                        <TextField
                          autoFocus={true}
                          inputRef={el => (this.nom_lycee_arabicRef = el)}
                          placeholder="إسم الثانوية"
                          className="form-control"
                          id="nom_lycee_arabic"
                        />
                      </div>
                      <div className="form-group col-sm col-md col-lg">
                        <TextField
                          autoFocus={true}
                          inputRef={el => (this.lieu_lycee_arabicRef = el)}
                          placeholder="مقر الثانوية"
                          className="form-control"
                          id="lieu_lycee_arabic"
                        />
                      </div>
                     </div>
                    <div className="form-row">
                      <div className="form-group col-sm col-md col-lg">
                        <TextField
                          autoFocus={true}
                          inputRef={el => (this.commune_arabicRef = el)}
                          placeholder="البلدية"
                          className="form-control"
                          id="commune_arabic"
                        />
                      </div>
                      <div className="form-group col-sm col-md col-lg">
                        <TextField
                          autoFocus={true}
                          inputRef={el => (this.daira_arabicRef = el)}
                          placeholder="الدائرة"
                          className="form-control"
                          id="daira_arabic"
                        />
                      </div>
                      <div className="form-group col-sm col-md col-lg">
                        <TextField
                          autoFocus={true}
                          inputRef={el => (this.wilaya_arabicRef = el)}
                          placeholder="الولاية"
                          className="form-control"
                          id="wilaya_arabic"
                        />
                      </div>
                     </div>
                     <div className="form-row">
                    <div className="form-group col-sm col-md col-lg">
                        <TextField
                          autoFocus={true}
                          inputRef={el => (this.fonction_parent_arabicRef = el)}
                          placeholder="مهنة الأب"
                          className="form-control"
                          id="fonction_parent_arabic"
                        />
                      </div>
                      <div className="form-group col-sm col-md col-lg">
                        <TextField
                          autoFocus={true}
                          inputRef={el => (this.maladies_arabicRef = el)}
                          placeholder="الحالة الصحية"
                          className="form-control"
                          id="maladies_arabic"
                        />
                      </div>
                      </div>
                      <div className="form-row">
                      <div className="form-group col-sm col-md col-lg">
                        <TextField
                          autoFocus={true}
                          inputRef={el => (this.specialite_arabicRef = el)}
                          placeholder="التخصص"
                          className="form-control"
                          id="specialite_arabic"
                        />
                      </div>
                      <div className="form-group col-sm col-md col-lg">
                        <TextField
                          autoFocus={true}
                          inputRef={el => (this.niveau_arabicRef = el)}
                          placeholder="المستوى"
                          className="form-control"
                          id="niveau_arabic"
                        />
                      </div>
                      </div>
                     <div className="form-row">
                    <div className="form-group col-sm col-md col-lg">
                        <TextField
                          autoFocus={true}
                          inputRef={el => (this.adresse_arabicRef = el)}
                          placeholder="العنوان"
                          className="form-control"
                          id="adresse_arabic"
                        />
                      </div>
                      </div>
                      <div className="form-row">
                      <div className="form-group col-sm col-md col-lg">
                        <TextField
                          autoFocus={true}
                          inputRef={el => (this.polyclinique_arabicRef = el)}
                          placeholder="مستوصف"
                          className="form-control"
                          id="polyclinique_arabic"
                        />
                      </div>
                      <div className="form-group col-sm col-md col-lg">
                        <TextField
                          autoFocus={true}
                          inputRef={el => (this.lieu_polyclinique_arabicRef = el)}
                          placeholder="مقر المستوصف"
                          className="form-control"
                          id="lieu_polyclinique_arabic"
                        />
                      </div>
                      </div>
                      <div className="form-row">
                      <div className="form-group col-sm col-md col-lg">
                        <TextField
                          autoFocus={true}
                          inputRef={el => (this.chu_arabicRef = el)}
                          placeholder="مركز الاستشفائي-جامعي"
                          className="form-control"
                          id="chu_arabic"
                        />
                      </div>
                      <div className="form-group col-sm col-md col-lg">
                        <TextField
                          autoFocus={true}
                          inputRef={el => (this.lieu_chu_arabicRef = el)}
                          placeholder="مقر المركز الاستشفائي-جامعي"
                          className="form-control"
                          id="lieu_chu_arabic"
                        />
                      </div>
                      </div>
                      <div className="form-row">
                      <div className="form-group col-sm col-md col-lg">
                        <TextField
                          autoFocus={true}
                          inputRef={el => (this.epsp_arabicRef = el)}
                          placeholder="مؤسسة العمومية للصحة الجوارية"
                          className="form-control"
                          id="epsp_arabic"
                        />
                      </div>
                      <div className="form-group col-sm col-md col-lg">
                        <TextField
                          autoFocus={true}
                          inputRef={el => (this.lieu_epsp_arabicRef = el)}
                          placeholder="مقر المؤسسة العمومية للصحة الجوارية"
                          className="form-control"
                          id="lieu_epsp_arabic"
                        />
                      </div>
                      </div>
                      <div className="form-row">
                      <div className="form-group col-sm col-md col-lg">
                        <TextField
                          autoFocus={true}
                          inputRef={el => (this.eph_arabicRef = el)}
                          placeholder="مؤسسة العمومية الاستشفائية"
                          className="form-control"
                          id="eph_arabic"
                        />
                      </div>
                      <div className="form-group col-sm col-md col-lg">
                        <TextField
                          autoFocus={true}
                          inputRef={el => (this.lieu_eph_arabicRef = el)}
                          placeholder="مقر المؤسسة العمومية الاستشفائية"
                          className="form-control"
                          id="lieu_eph_arabic"
                        />
                      </div>
                      </div>
                  </TabPane>
                  <TabPane tabId="3">
                  <div className="form-row">
                      <div className="form-group col-sm col-md col-lg">
                        <TextField
                          autoFocus={true}
                          inputRef={el => (this.emailRef = el)}
                          placeholder="email"
                          className="form-control"
                          id="email"
                        />
                      </div>
                      <div className="form-group col-sm col-md col-lg">
                        <TextField
                          autoFocus={true}
                          inputRef={el => (this.cinRef = el)}
                          placeholder="Numéro piece d'idendité"
                          className="form-control"
                          id="cin"
                        />
                      </div>
                    </div>
                    <div className="form-row">
                    <div className="form-group col-sm col-md col-lg">
                        <TextField
                          autoFocus={true}
                          inputRef={el => (this.phone1Ref = el)}
                          placeholder="Télephone personnel"
                          className="form-control"
                          id="phone1"
                        />
                      </div>
                      <div className="form-group col-sm col-md col-lg">
                        <TextField
                          autoFocus={true}
                          inputRef={el => (this.phone2Ref = el)}
                          placeholder="Télephone du père"
                          className="form-control"
                          id="phone2"
                        />
                      </div>
                      <div className="form-group col-sm col-md col-lg">
                        <TextField
                          autoFocus={true}
                          inputRef={el => (this.phone3Ref = el)}
                          placeholder="Télephone de la mère"
                          className="form-control"
                          id="phone3"
                        />
                      </div>
                      <div className="form-group col-sm col-md col-lg">
                        <TextField
                          autoFocus={true}
                          inputRef={el => (this.phone4Ref = el)}
                          placeholder="Télephone de la maison"
                          className="form-control"
                          id="phone4"
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-sm col-md col-lg">
                        <label>Date de naissance </label>
                        <DatePicker
                          type="text"
                          required
                          selected={this.state.startDate}
                          dateFormat="yyyy-MM-dd"
                          className="form-control"
                          onChange={this.handleChange}
                          id="birthday"
                        />
                      </div>
                    </div>
                  </TabPane>
                </TabContent>
              </div>
              <div className="form-group col-md">
                <button
                  className="btn btn-danger btn-block"
                  onClick={this.toggle}
                >
                  Save
                </button>
              </div>
            </form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
export default PreinscriptionModal;

PreinscriptionModal.propTypes = {
  createPreinscription: PropTypes.func.isRequired
};
