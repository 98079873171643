import axios from 'axios'
const csrfToken = document.querySelector("meta[name=csrf-token]").content;
axios.defaults.headers.common["X-CSRF-Token"] = csrfToken;
const setAxiosHeaders = () => {
    const csrfToken = document.querySelector("meta[name=csrf-token]").content
    if (!csrfToken) {
        return
    }
    axios.defaults.headers.common["X-CSRF-Token"] = csrfToken
}

export default setAxiosHeaders
