import React from 'react'
import PropTypes from 'prop-types'
import {Modal, ModalHeader, ModalBody } from 'reactstrap';
import Button from '@material-ui/core/Button';
import axios from 'axios'
import setAxiosHeaders from './AxiosHeaders'
import Preinscription from "./User";
import moment from 'moment'
import DatePicker from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import TextField from '@material-ui/core/TextField'
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

class UserModal extends  React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modal: false,
      startDate: new Date(),
      role: 'superadmin'
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.emailRef = React.createRef()
    this.usernameRef = React.createRef()
    this.roleRef = React.createRef()
  }

  toggle = () => {
    this.setState(prevState => ({
      modal: !prevState.modal
    }))
  }
  handleSubmit(e) {
    e.preventDefault()
    axios
      .post('/api/v1/users', {
        user: {
          email:this.emailRef.value,
          username: this.usernameRef.value,
          role: this.roleRef.value,
        },
      })
      .then(response => {
        const user = response.data
        toastr.success('user created!')
        this.props.createUser(user)
      })
      .catch(error => {
        console.log(error)
      })
    e.target.reset()
  }
  handleChange(e) {
     this.setState({role: e.target.value})
  }

  render() {
      const element = <FontAwesomeIcon icon={faPlusCircle} />
      const closeBtn = <button className="close" onClick={this.toggle}>&times;</button>
      const label = this.props.buttonLabel
      let button = ''
      let title = 'Add new user'
        button = <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={this.toggle}
                    startIcon={<AddCircleOutlineOutlinedIcon />}
                  >
                    Add user
                </Button>
      return (
      <div>
        {button}
        <Modal size="xl" isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
          <ModalHeader toggle={this.toggle} close={closeBtn}>{title}</ModalHeader>
          <ModalBody>
          <form onSubmit={this.handleSubmit} className="my-3">
            <div className="form-row">
              <div className="form-group col-sm col-md col-lg">
                <TextField
                  autoFocus={true}
                  inputRef={el => this.emailRef = el}
                  placeholder="email"
                  className="form-control"
                  id="email"
                />
              </div>
              <div className="form-group col-sm col-md col-lg">
                <TextField
                  autoFocus={true}
                  inputRef={el => this.usernameRef = el}
                  placeholder="username"
                  className="form-control"
                  id="username"
                />
               </div>
              </div>
              <div className="form-row">
                <div className="form-group col-sm col-md col-lg">
                    <Select
                       labelId="role"
                       value={this.state.role}
                       inputRef={el => this.roleRef = el}
                       className="form-control"

                       variant="outlined"
                       name="select"
                       onChange={this.handleChange}
                       >
                       <MenuItem value={'superadmin'}>Super Admin</MenuItem>
                       <MenuItem value={'admin'}>Admin</MenuItem>
                       <MenuItem value={'school'}>School</MenuItem>
                       <MenuItem value={'author'}>Author</MenuItem>
                       <MenuItem value={'student'}>Student</MenuItem>
                     </Select>
                 </div>
               </div>
              <div className="form-group col-md">
                <button className="btn btn-danger btn-block" onClick={this.toggle}>
                  Save
                </button>
              </div>
          </form>
          </ModalBody>
        </Modal>
      </div>
    )
  }
}
export default UserModal

UserModal.propTypes = {
  createUser: PropTypes.func.isRequired,
}
