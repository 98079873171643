import React from "react";
import PropTypes from "prop-types";
import axios from "axios";
import setAxiosHeaders from "./AxiosHeaders";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Container, Row, Col } from "reactstrap";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";

class Preinscriptions extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handlePage = this.handlePage.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.state = {
      loading: true,
      metaData: []
    };
  }
  handleClick() {
    this.props.toggleCompletedPreinscriptions();
  }
  handleChange(input) {
    this.props.getAutoCompleteResults(input);
    console.log(input.target.value);
  }
  handlePage = (event, value) => {
    console.log(value);
    console.log(this.props.pageId);
    this.props.setPageNumber(value);
    console.log(this.props.pageId);
  };
  render() {
    return (
      <>
        <hr />
        <TextField
          id="input-with-icon-textfield"
          className="form-control"
          variant="outlined"
          ref={input => {
            this.searchBar = input;
          }}
          value={this.props.term}
          label="Rechercher"
          onChange={input => this.handleChange(input)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchOutlinedIcon />
              </InputAdornment>
            )
          }}
        />
        <hr />
        <button
          className="btn btn-outline-primary btn-block mb-3"
          onClick={this.handleClick}
        >
          {this.props.hideCompletedPreinscriptions
            ? `Afficher toutes les demandes d'inscriptions`
            : `Cacher les inscriptions completes`}
        </button>
        <Container>
          <Pagination
            count={this.props.preinscriptions.pages}
            page={this.props.preinscriptions.page}
            onChange={this.handlePage}
            variant="outlined"
            shape="rounded"
            color="secondary"
            showFirstButton
            showLastButton
          />
        </Container>
        <hr />
        <div className="table-responsive">
          <table className="table table-border table-sm">
            <thead className="thead-dark">
             
            </thead>
            <tbody>{this.props.children}</tbody>
          </table>
        </div>
        <Container>
          <Pagination
            count={this.props.preinscriptions.pages}
            page={this.props.preinscriptions.page}
            onChange={this.handlePage}
            variant="outlined"
            showFirstButton
            showLastButton
            shape="rounded"
            color="secondary"
          />
        </Container>
      </>
    );
  }
}
export default Preinscriptions;

Preinscriptions.propTypes = {
  toggleCompletedPreinscriptions: PropTypes.func.isRequired,
  preinscriptions: PropTypes.object.isRequired,
  hideCompletedPreinscriptions: PropTypes.bool.isRequired,
  getAutoCompleteResults: PropTypes.func.isRequired,
  setPageNumber: PropTypes.func.isRequired,
  term: PropTypes.string.isRequired,
  pageId: PropTypes.number.isRequired
};
