import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import axios from 'axios'
import setAxiosHeaders from './AxiosHeaders'
import DatePicker from 'react-datepicker';
import moment from 'moment'
import Modal from "react-bootstrap/Modal";
import "react-datepicker/dist/react-datepicker.css";
import TextField from '@material-ui/core/TextField'
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

class User extends React.Component {
  constructor(props) {
    super(props)
        this.state={role: this.props.user.role,}
        this.handleDestroy = this.handleDestroy.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.updateUser = this.updateUser.bind(this)
        this.inputUsernameRef = React.createRef()
        this.inputEmailRef = React.createRef()
        this.inputRoleRef = React.createRef()
        this.path = `/api/v1/users/${this.props.user.id}`
  }
  handleChange(e) {
    console.log(e.target.name);
    if(e.target.name=="select") this.setState({role: e.target.value})
    this.updateUser()
  }
  updateUser = _.debounce(() => {
    setAxiosHeaders()
      axios
        .put(this.path, {
            user: {
                email: this.inputEmailRef.value,
                username: this.inputUsernameRef.value,
                role: this.inputRoleRef.value,
              },
            })
            .then(() => {
                this.props.clearErrors()
            })
            .catch(error => {
                this.props.handleErrors(error)
            })
    toastr.info('User informations updated!')}, 1500)
    handleDestroy() {
        setAxiosHeaders()
        const confirmation = confirm('Are you sure?')
        if (confirmation) {
            axios
                .delete(this.path)
                .then(response => {
                    this.props.getUsers(),
                    toastr.error('User deleted succefully!')
                })
                .catch(error => {
                    console.log(error)
                })
        }
    }
  render() {
    const { user } = this.props
    return (
      <tr>
      <td>
          <TextField
            defaultValue={user.email}
            inputRef={el => this.inputEmailRef = el}
            placeholder="email"
            label="email"
            className="form-control"
            onChange={this.handleChange}
            variant="outlined"
            id={`user__email-${user.id}`}
          />
      </td>
      <td>
          <TextField
            defaultValue={user.username}
            inputRef={el => this.inputUsernameRef = el}
            placeholder="username"
            label="username"
            className="form-control"
            onChange={this.handleChange}
            variant="outlined"
            id={`user__username-${user.id}`}
          />
      </td>
      <td>
          <Select
             labelId="role"
             value={this.state.role}
             inputRef={el => this.inputRoleRef = el}
             className="form-control"
             variant="outlined"
             name="select"
             onChange={this.handleChange}
             inputProps={{ 'aria-label': 'role' }}
             id={`user__role-${user.id}`}
             >
             <MenuItem value={'superadmin'}>Super Admin</MenuItem>
             <MenuItem value={'admin'}>Admin</MenuItem>
             <MenuItem value={'school'}>School</MenuItem>
             <MenuItem value={'author'}>Author</MenuItem>
             <MenuItem value={'student'}>Student</MenuItem>
           </Select>
      </td>
      <td>
          <button
            onClick={this.handleDestroy}
            className="btn btn-outline-danger"
                  >
              Delete
          </button>
    </td>
    </tr>
    )
  }
}

export default User

User.propTypes = {
  user: PropTypes.object.isRequired,
  getUsers: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
}
