import React from 'react'
import PropTypes from 'prop-types'
import axios from "axios";
import ErrorMessage from './ErrorMessage'
import "react-datepicker/dist/react-datepicker.css";
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment';



class Users extends React.Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(input) {
    this.props.getAutoCompleteResults(input)
  }
  render() {
    return (
      <>
    <hr/>
      <div>
        <TextField
           id="input-with-icon-textfield"
           className="form-control"
           ref={ (input) => { this.searchBar = input } }
           value={ this.props.term }
           label="Search"
           onChange={ (input)=>this.handleChange(input) }
           InputProps={{
             startAdornment: (
               <InputAdornment position="start">
                 <SearchOutlinedIcon />
               </InputAdornment>
             ),
           }}
         />
      </div>
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Email</th>
                <th scope="col">Username</th>
                <th scope="col">Role</th>
                <th scope="col" className="text-right">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>{this.props.children}</tbody>
          </table>
        </div>
      </>
    )
  }
}
export default Users

Users.propTypes = {
    getAutoCompleteResults: PropTypes.func.isRequired,
    term: PropTypes.string.isRequired,
}
