import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import axios from "axios";
import setAxiosHeaders from "./AxiosHeaders";
import DatePicker from "react-datepicker";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import "react-datepicker/dist/react-datepicker.css";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

class Preinscription extends React.Component {
  constructor(props) {
    super(props);
    this.handleDestroy = this.handleDestroy.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeB = this.handleChangeB.bind(this);
    this.updatePreinscription = this.updatePreinscription.bind(this);
    this.inputLastRef = React.createRef();
    this.inputFirstRef = React.createRef();
    this.inputEmailRef = React.createRef();
    this.inputPhone1Ref = React.createRef();
    this.inputPhone2Ref = React.createRef();
    this.inputPhone3Ref = React.createRef();
    this.inputPhone4Ref = React.createRef();
    this.inputEmailRef = React.createRef();
    this.inputBirthdayRef = React.createRef();
    this.completedRef = React.createRef();
    this.inputAddressRef= React.createRef();
    this.inputCityRef= React.createRef();
    this.inputWilayaRef= React.createRef();
    this.inputZipRef= React.createRef();
    this.inputEmailRef= React.createRef();
    this.inputNom_prenom_mereRef= React.createRef();
    this.inputNom_pereRef= React.createRef();
    this.inputNom_arabicRef= React.createRef();
    this.inputPrenom_arabicRef= React.createRef();
    this.inputLieu_naissance_arabicRef= React.createRef();
    this.bd = this.props.preinscription.birthday;
    this.path = `/api/v1/preinscriptions/${this.props.preinscription.id}`;
    this.state = {
      complete: this.props.preinscription.complete,
      invited: this.props.preinscription.invited,
      startDate: moment(this.bd).toDate()
    };
  }

  handleChange(date, flag) {
    if (flag == 1) {
      this.setState({
        startDate: date
      });
      this.inputBirthdayRef = moment(date).format("DD/MM/YYYY");
    }
    this.updatePreinscription();
  }
  handleChangeB(event) {
    console.log(event.target.name);
    console.log(event.target.checked);
    this.setState({
      [event.target.name]: event.target.checked
    });
    this.updatePreinscription();
  }
  updatePreinscription = _.debounce(() => {
    setAxiosHeaders();
    axios
      .put(this.path, {
        preinscription: {
          first_name: this.inputFirstRef.current.value,
          last_name: this.inputLastRef.current.value,
          email: this.inputEmailRef.current.value,
          birthday: this.inputBirthdayRef,
          phone1: this.inputPhone1Ref.current.value,
          phone2: this.inputPhone2Ref.current.value,
          phone3: this.inputPhone3Ref.current.value,
          phone4: this.inputPhone4Ref.current.value,
          complete: this.state.complete,
          invited: this.state.invited,
          address: this.inputAddressRef.current.value, 
          city: this.inputCityRef.current.value, 
          wilaya: this.inputWilayaRef.current.value, 
          zip: this.inputZipRef.current.value, 
          email: this.inputEmailRef.current.value,
          nom_prenom_mere: this.inputNom_prenom_mereRef.current.value,
          nom_pere: this.inputNom_pereRef.current.value,
          nom_arabic: this.inputNom_arabicRef.current.value,
          prenom_arabic: this.inputPrenom_arabicRef.current.value,
          lieu_naissance_arabic: this.inputLieu_naissance_arabicRef.current.value
        }
      })
      .then(() => {
        this.props.clearErrors();
      })
      .catch(error => {
        this.props.handleErrors(error);
      });
    toastr.info("Prinscription updated!");
  }, 2000);
  handleDestroy() {
    setAxiosHeaders();
    const confirmation = confirm("Vous eêtes sûre?");
    if (confirmation) {
      axios
        .delete(this.path)
        .then(response => {
          this.props.getPreinscriptions(1),
            toastr.error("Prinscription supprimée !");
        })
        .catch(error => {
          console.log(error);
        });
    }
  }
  handleEdit() {
    setAxiosHeaders();
    const confirmation = confirm("Vous eêtes sûre?");
    if (confirmation) {
      axios
        .delete(this.path)
        .then(response => {
          this.props.getPreinscriptions(1),
            toastr.error("Prinscription supprimée !");
        })
        .catch(error => {
          console.log(error);
        });
    }
  }

  render() {
    const mystyle = {
      flex: 1,
      fontSize: 13
    };
    const { preinscription } = this.props;
    return (
      <tr
        className={`${
          this.state.invited && this.props.hideCompletedPreinscriptions
            ? `d-none`
            : ""
        } ${this.state.complete ? "table-sm" : "bg-warning"}`}
      >
        <td>
          <Checkbox
            checked={this.state.complete}
            checkedIcon={<LockOutlinedIcon />}
            icon={<LockOpenIcon />}
            color="primary"
            name="complete"
            style={mystyle}
            inputRef={el => (this.completedRef = el)}
            onChange={this.handleChangeB}
            inputProps={{ "aria-label": "checkbox with default color" }}
          />
        </td>
        <td>
        <TextField
          disabled={this.state.complete}
          ref={this.inputFirstRef}
          id={`preinscription__first_name-${preinscription.id}`}
          label="Nom:"
          onChange={this.handleChange}
          defaultValue={preinscription.first_name}
        />
        </td>
        <td>
        <TextField
          disabled={this.state.complete}
          ref={this.inputLastRef}
          id={`preinscription__last_name-${preinscription.id}`}
          label="Prénom:"
          onChange={this.handleChange}
          defaultValue={preinscription.last_name}
        />
        </td>
        <td>
        <TextField
          disabled={this.state.complete}
          ref={this.inputEmailRef}
          id={`preinscription__email-${preinscription.id}`}
          label="Email:"
          onChange={this.handleChange}
          defaultValue={preinscription.email}
        />
        </td>
        <td>
        <TextField
          disabled={this.state.complete}
          ref={this.inputPhone1Ref}
          id={`preinscription__phone1-${preinscription.id}`}
          label="Tel Perso:"
          onChange={this.handleChange}
          defaultValue={preinscription.phone1}
        />
        </td>
        <td>
        <TextField
          disabled={this.state.complete}
          ref={this.inputPhone2Ref}
          id={`preinscription__phone2-${preinscription.id}`}
          label="Tel Père:"
          onChange={this.handleChange}
          defaultValue={preinscription.phone2}
        />
        </td>
        <td>
        <TextField
          disabled={this.state.complete}
          ref={this.inputPhone3Ref}
          id={`preinscription__phone3-${preinscription.id}`}
          label="Tel Mère:"
          onChange={this.handleChange}
          defaultValue={preinscription.phone3}
        />
        </td>
        <td>
        <TextField
          disabled={this.state.complete}
          ref={this.inputPhone4Ref}
          id={`preinscription__phone4-${preinscription.id}`}
          label="Tel Maison:"
          onChange={this.handleChange}
          defaultValue={preinscription.phone4}
        />
        </td>



        <td>
        <TextField
         hidden={this.state.complete}
          ref={this.inputAddressRef}
          id={`preinscription__adresse-${preinscription.id}`}
          label="Adresse:"
          onChange={this.handleChange}
          defaultValue={preinscription.address}
        />
        </td>
        <td>
        <TextField
         hidden={this.state.complete}
         ref={this.inputCityRef}
          id={`preinscription__city-${preinscription.id}`}
          label="Commune:"
          onChange={this.handleChange}
          defaultValue={preinscription.city}
        />
        </td>
        <td>
        <TextField
         hidden={this.state.complete}
         ref={this.inputWilayaRef}
          id={`preinscription__wilaya-${preinscription.id}`}
          label="Wilaya:"
          onChange={this.handleChange}
          defaultValue={preinscription.wilaya}
        />
        </td>
        <td>
        <TextField
         hidden={this.state.complete}
         ref={this.inputZipRef}
          id={`preinscription__npmere-${preinscription.id}`}
          label="CPostal:"
          onChange={this.handleChange}
          defaultValue={preinscription.zip}
        />
        </td>
        <td>
        <TextField
         hidden={this.state.complete}
         ref={this.inputNom_prenom_mereRef}
          id={`preinscription__npmere-${preinscription.id}`}
          label="Mère:"
          onChange={this.handleChange}
          defaultValue={preinscription.nom_prenom_mere}
        />
        </td>
        <td>
        <TextField
         hidden={this.state.complete}
         ref={this.inputNom_pereRef}
          id={`preinscription__npere-${preinscription.id}`}
          label="Père:"
          onChange={this.handleChange}
          defaultValue={preinscription.nom_pere}
        />
        </td>
        <td>
        <TextField
         hidden={this.state.complete}
         ref={this.inputNom_arabicRef}
          id={`preinscription__nom_arabic-${preinscription.id}`}
          label="اللقب:"
          onChange={this.handleChange}
          defaultValue={preinscription.nom_arabic}
        />
        </td>
        <td>
        <TextField
         hidden={this.state.complete}
         ref={this.inputPrenom_arabicRef}
          id={`preinscription__prenom_arabic-${preinscription.id}`}
          label="الاسم:"
          onChange={this.handleChange}
          defaultValue={preinscription.prenom_arabic}
        />
        </td>
        <td>
        <TextField
         hidden={this.state.complete}
         ref={this.inputLieu_naissance_arabicRef}
          id={`preinscription__lieu_naissance_arabic-${preinscription.id}`}
          label="مكان الازدياد:"
          onChange={this.handleChange}
          defaultValue={preinscription.lieu_naissance_arabic}
        />
        </td>
        <td>
          <DatePicker
            type="text"
            required
            selected={this.state.startDate}
            disabled={this.state.complete}
            style={mystyle}
            dateFormat="yyyy-MM-dd"
            className="form-control"
            name="datepicker"
            onChange={date => this.handleChange(date, 1)}
            id="birthday"
          />
        </td>
        <td className="text-right">
          <FormControlLabel
            style={mystyle}
            control={
              <Checkbox
                color="default"
                checked={this.state.invited}
                color="primary"
                onChange={this.handleChangeB}
                fontSize="small"
                name="invited"
              />
            }
            label="Inscrit?"
          />
          <button
            onClick={this.handleDestroy}
            className="btn btn-outline-danger"
          >
            Supprimer
          </button>
        </td>
      </tr>
    );
  }
}

export default Preinscription;

Preinscription.propTypes = {
  preinscription: PropTypes.object.isRequired,
  getPreinscriptions: PropTypes.func.isRequired,
  hideCompletedPreinscriptions: PropTypes.bool.isRequired,
  clearErrors: PropTypes.func.isRequired
};
