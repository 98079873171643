import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import Button from "@material-ui/core/Button";
import axios from "axios";
import setAxiosHeaders from "./AxiosHeaders";
import Preinscription from "./Preinscription";
import GetAppIcon from "@material-ui/icons/GetApp";

class PreinscriptionLoadModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  toggle = () => {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  };
  handleChange = e => {
    if (e.target.files[0]) this.setState({ cvs: e.target.files[0] });
    console.log(e.target.files[0]);
  };
  handleSubmit(e) {
    const formData = new FormData();
    formData.append("file", this.state.cvs);
    console.log(formData);
    /*let options = {
        method: 'POST',
        headers: setAxiosHeaders(),
        body: formData
     }
    // configure your fetch url appropriately
    fetch('/api/v1/preinscriptions/csvloader', options)
      .then(resp => resp.json())
      .then(result => {
        alert(result.message)
        });
*/
    setAxiosHeaders();
    axios
      .post("/api/v1/preinscriptions/csvloader", formData)
      .then(response => response.json());
  }

  render() {
    const closeBtn = (
      <button className="close" onClick={this.toggle}>
        &times;
      </button>
    );
    const label = this.props.buttonLabel;
    let button = "";
    let title = "Chargement de fichier";
    button = (
      <Button
        variant="contained"
        onClick={this.toggle}
        startIcon={
          <svg
            className="bi bi-download"
            width="1em"
            height="1em"
            viewBox="0 0 16 16"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M.5 8a.5.5 0 01.5.5V12a1 1 0 001 1h12a1 1 0 001-1V8.5a.5.5 0 011 0V12a2 2 0 01-2 2H2a2 2 0 01-2-2V8.5A.5.5 0 01.5 8z"
              clipRule="evenodd"
            />
            <path
              fillRule="evenodd"
              d="M5 7.5a.5.5 0 01.707 0L8 9.793 10.293 7.5a.5.5 0 11.707.707l-2.646 2.647a.5.5 0 01-.708 0L5 8.207A.5.5 0 015 7.5z"
              clipRule="evenodd"
            />
            <path
              fillRule="evenodd"
              d="M8 1a.5.5 0 01.5.5v8a.5.5 0 01-1 0v-8A.5.5 0 018 1z"
              clipRule="evenodd"
            />
          </svg>
        }
      >
        Charger données
      </Button>
    );
    return (
      <div>
        {button}
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggle} close={closeBtn}>
            {title}
          </ModalHeader>
          <ModalBody>
            <form onSubmit={this.handleSubmit} className="my-3">
              <div className="form-row">
                <div className="form-group col-sm col-md col-lg">
                  <input
                    type="file"
                    label="Charger EXCEL"
                    placeholder="Charger EXCEL..."
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="form-group col-md">
                <button
                  className="btn btn-danger btn-block"
                  onClick={this.toggle}
                >
                  Charger
                </button>
              </div>
            </form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
export default PreinscriptionLoadModal;
