import React from "react";
import ReactDOM from "react-dom";
import { Container, Row, Col } from "reactstrap";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import * as FileSaver from "file-saver";
import * as Excel from "exceljs/dist/exceljs";
import Button from "@material-ui/core/Button";
import VerticalAlignTopIcon from "@material-ui/icons/VerticalAlignTop";

import Preinscriptions from "./Preinscriptions";
import Preinscription from "./Preinscription";
import PreinscriptionModal from "./PreinscriptionModal";
import PreinscriptionLoadModal from "./PreinscriptionLoadModal";
import Spinner from "./Spinner";
import ErrorMessage from "./ErrorMessage";

class PreinscrpionPartial extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      preinscriptions: [],
      hideCompletedPreinscriptions: false,
      isLoading: true,
      errorMessage: null,
      term: "",
      pageId: 1,
    };
    this.getPreinscriptions = this.getPreinscriptions.bind(this);
    this.setPageNumber = this.setPageNumber.bind(this);
    this.getAutoCompleteResults = this.getAutoCompleteResults.bind(this);
    this.createPreinscription = this.createPreinscription.bind(this);
    this.toggleCompletedPreinscriptions =
      this.toggleCompletedPreinscriptions.bind(this);
    this.handleErrors = this.handleErrors.bind(this);
    this.clearErrors = this.clearErrors.bind(this);
  }
  componentDidMount() {
    this.getPreinscriptions();
  }
  getAutoCompleteResults(e) {
    this.setState(
      {
        term: e.target.value,
      },
      () => {
        axios
          .get("/api/v1/search_preinscriptions?q=" + this.state.term)
          .then((response) => {
            console.log(response.data);
            this.setState({
              preinscriptions: response.data,
            });
          });
      }
    );
  }
  setPageNumber(page) {
    this.setState(
      {
        pageId: page,
      },
      () => {
        this.getPreinscriptions();
      }
    );
    this.setState({ pageId: page });
  }
  getPreinscriptions() {
    axios
      .get("/api/v1/preinscriptions/?page=" + this.state.pageId)
      .then((response) => {
        this.clearErrors();
        this.setState({ isLoading: true });
        const preinscriptions = response.data;
        this.setState({ preinscriptions });
        this.setState({ isLoading: false });
      })
      .catch((error) => {
        this.setState({ isLoading: true });
        this.setState({
          errorMessage: {
            message:
              "Un problème est survenu lors du chargement des preinscriptions...",
          },
        });
      });
  }
  exportExcel(csvData, fileName) {
    const workbook = new ExcelJS.Workbook();
    const ws = workbook.addWorksheet("Contacts", {
      pageSetup: { paperSize: 9, orientation: "landscape" },
    });
    const ws2 = workbook.addWorksheet("Informations personnelles", {
      pageSetup: { paperSize: 9, orientation: "landscape" },
    });
    const ws3 = workbook.addWorksheet("معلومات شخصية", {
      pageSetup: { paperSize: 9, orientation: "landscape" },
    });
    const ws4 = workbook.addWorksheet("Informations structures Hospitalières", {
      pageSetup: { paperSize: 9, orientation: "landscape" },
    });
    const ws5 = workbook.addWorksheet("معلومات المؤسسات الاستشفائية", {
      pageSetup: { paperSize: 9, orientation: "landscape" },
    });
    ws.pageSetup.margins = {
      left: 0.1,
      right: 0.1,
      top: 0.15,
      bottom: 0.15,
      header: 0.01,
      footer: 0.01,
    };
    ws2.pageSetup.margins = {
      left: 0.1,
      right: 0.1,
      top: 0.15,
      bottom: 0.15,
      header: 0.01,
      footer: 0.01,
    };
    ws3.pageSetup.margins = {
      left: 0.1,
      right: 0.1,
      top: 0.15,
      bottom: 0.15,
      header: 0.01,
      footer: 0.01,
    };
    ws4.pageSetup.margins = {
      left: 0.1,
      right: 0.1,
      top: 0.15,
      bottom: 0.15,
      header: 0.01,
      footer: 0.01,
    };
    ws5.pageSetup.margins = {
      left: 0.1,
      right: 0.1,
      top: 0.15,
      bottom: 0.15,
      header: 0.01,
      footer: 0.01,
    };
    ws.columns = [
      {
        header: "Id",
        key: "id",
        width: 10,
        style: { font: { name: "Arial", size: 10 } },
      },
      {
        header: "email",
        key: "email",
        width: 20,
        style: { font: { name: "Arial", size: 10 } },
      },
      {
        header: "Num CIN",
        key: "cin",
        width: 20,
        style: { font: { name: "Arial", size: 10 } },
      },
      {
        header: "Telephone perso",
        key: "phone1",
        width: 20,
        style: { font: { name: "Arial", size: 10 } },
      },
      {
        header: "Telephone père",
        key: "phone2",
        width: 20,
        style: { font: { name: "Arial", size: 10 } },
      }
      ,
      {
        header: "Telephone mère",
        key: "phone3",
        width: 20,
        style: { font: { name: "Arial", size: 10 } },
      },
      {
        header: "Telephone maison",
        key: "phone4",
        width: 20,
        style: { font: { name: "Arial", size: 10 } },
      }
    ];
    ws2.columns = [
      {
        header: "Nom",
        key: "first_name",
        width: 20,
        style: { font: { name: "Arial", size: 10 } },
      },
      {
        header: "Prénom",
        key: "last_name",
        width: 20,
        style: { font: { name: "Arial", size: 10 } },
      },
      {
        header: "Lieu naissance",
        key: "birthplace",
        width: 20,
        style: { font: { name: "Arial", size: 10 } },
      },
      {
        header: "Adresse",
        key: "address",
        width: 40,
        style: { font: { name: "Arial", size: 10 } },
      },
      {
        header: "Commune",
        key: "city",
        width: 20,
        style: { font: { name: "Arial", size: 10 } },
      },
      {
        header: "Daira",
        key: "daira",
        width: 20,
        style: { font: { name: "Arial", size: 10 } },
      },
      {
        header: "Wilaya",
        key: "wilaya",
        width: 20,
        style: { font: { name: "Arial", size: 10 } },
      },
      {
        header: "Code postal",
        key: "zip",
        width: 20,
        style: { font: { name: "Arial", size: 10 } },
      },
      {
        header: "Prénom père",
        key: "nom_pere",
        width: 20,
        style: { font: { name: "Arial", size: 10 } },
      },
      {
        header: "Nom et Prénom mère",
        key: "nom_prenom_mere",
        width: 20,
        style: { font: { name: "Arial", size: 10 } },
      },
      {
        header: "Nom du lycée",
        key: "nom_lycee",
        width: 20,
        style: { font: { name: "Arial", size: 10 } },
      },
      {
        header: "Lieu du lycée",
        key: "lieu_lycee",
        width: 20,
        style: { font: { name: "Arial", size: 10 } },
      },
      {
        header: "Niveau",
        key: "niveau",
        width: 20,
        style: { font: { name: "Arial", size: 10 } },
      },
      {
        header: "Spéciliatité",
        key: "specialite",
        width: 20,
        style: { font: { name: "Arial", size: 10 } },
      },
      {
        header: "Régime",
        key: "regime",
        width: 20,
        style: { font: { name: "Arial", size: 10 } },
      },
      {
        header: "Maladies",
        key: "maladies",
        width: 20,
        style: { font: { name: "Arial", size: 10 } },
      },
      {
        header: "Fonctions parents",
        key: "fonction_parent",
        width: 20,
        style: { font: { name: "Arial", size: 10 } },
      }
    ]
  ws3.columns = [
      {
        header: "اللقب",
        key: "nom_arabic",
        width: 20,
        style: { font: { name: "Arial", size: 10 } },
      },
      {
        header: "الاسم",
        key: "prenom_arabic",
        width: 20,
        style: { font: { name: "Arial", size: 10 } },
      },
      {
        header: "مكان الميلاد",
        key: "lieu_naissance_arabic",
        width: 20,
        style: { font: { name: "Arial", size: 10 } },
      },
      {
        header: "العنوان",
        key: "adresse_arabic",
        width: 40,
        style: { font: { name: "Arial", size: 10 } },
      },
      {
        header: "البلدية",
        key: "commune_arabic",
        width: 20,
        style: { font: { name: "Arial", size: 10 } },
      },
      {
        header: "الدائرة",
        key: "daira_arabic",
        width: 20,
        style: { font: { name: "Arial", size: 10 } },
      },
      {
        header: "الولاية",
        key: "wilaya_arabic",
        width: 20,
        style: { font: { name: "Arial", size: 10 } },
      },
      {
        header: "الرمز البريدي",
        key: "zip",
        width: 20,
        style: { font: { name: "Arial", size: 10 } },
      },
      {
        header: "لقب الاب",
        key: "nom_pere_arabic",
        width: 20,
        style: { font: { name: "Arial", size: 10 } },
      },
      {
        header: "اسم و لقب الام",
        key: "nom_prenom_mere_arabic",
        width: 30,
        style: { font: { name: "Arial", size: 10 } },
      },
      {
        header: "اسم الثانوية",
        key: "nom_lycee_arabic",
        width: 30,
        style: { font: { name: "Arial", size: 10 } },
      },
      {
        header: "عنوان الثانوية",
        key: "lieu_lycee_arabic",
        width: 30,
        style: { font: { name: "Arial", size: 10 } },
      },
      {
        header: "المستوى",
        key: "niveau_arabic",
        width: 20,
        style: { font: { name: "Arial", size: 10 } },
      },
      {
        header: "الاختصاص",
        key: "specialite_arabic",
        width: 20,
        style: { font: { name: "Arial", size: 10 } },
      },
      {
        header: "النظام",
        key: "regime_arabic",
        width: 20,
        style: { font: { name: "Arial", size: 10 } },
      },
      {
        header: "الامراض",
        key: "maladies_arabic",
        width: 30,
        style: { font: { name: "Arial", size: 10 } },
      },
      {
        header: "وظيفة الاولياء",
        key: "fonction_parent_arabic",
        width: 30,
        style: { font: { name: "Arial", size: 10 } },
      }
    ]
    ws4.columns = [
      {
        header: "Polyclinique",
        key: "polyclinique",
        width: 30,
        style: { font: { name: "Arial", size: 10 } },
      },
      {
        header: "Lieu Polyclinique",
        key: "lieu_polyclinique",
        width: 30,
        style: { font: { name: "Arial", size: 10 } },
      },
      {
        header: "CHU",
        key: "chu",
        width: 30,
        style: { font: { name: "Arial", size: 10 } },
      },
      {
        header: "Lieu CHU",
        key: "lieu_chu",
        width: 30,
        style: { font: { name: "Arial", size: 10 } },
      },
      {
        header: "EPSP",
        key: "epsp",
        width: 30,
        style: { font: { name: "Arial", size: 10 } },
      },
      {
        header: "Lieu EPSP",
        key: "lieu_epsp",
        width: 30,
        style: { font: { name: "Arial", size: 10 } },
      },
      {
        header: "EPH",
        key: "eph",
        width: 30,
        style: { font: { name: "Arial", size: 10 } },
      },
      {
        header: "Lieu EPH",
        key: "lieu_eph",
        width: 30,
        style: { font: { name: "Arial", size: 10 } },
      }
    ]
    ws5.columns = [
      {
        header: "المستوصف",
        key: "polyclinique_arabic",
        width: 30,
        style: { font: { name: "Arial", size: 10 } },
      },
      {
        header: "مقر المستوصف",
        key: "lieu_polyclinique_arabic",
        width: 30,
        style: { font: { name: "Arial", size: 10 } },
      },
      {
        header: "CHU",
        key: "chu_arabic",
        width: 30,
        style: { font: { name: "Arial", size: 10 } },
      },
      {
        header: "مقر CHU",
        key: "lieu_chu_arabic",
        width: 30,
        style: { font: { name: "Arial", size: 10 } },
      },
      {
        header: "EPSP",
        key: "epsp_arabic",
        width: 30,
        style: { font: { name: "Arial", size: 10 } },
      },
      {
        header: "مقر EPSP",
        key: "lieu_epsp_arabic",
        width: 30,
        style: { font: { name: "Arial", size: 10 } },
      },
      {
        header: "EPH",
        key: "eph_arabic",
        width: 30,
        style: { font: { name: "Arial", size: 10 } },
      },
      {
        header: "مقر EPH",
        key: "lieu_eph_arabic",
        width: 30,
        style: { font: { name: "Arial", size: 10 } },
      }
    ]
    ws.getRow(1).font = {
      name: "Arial",
      family: 4,
      size: 11,
      bold: true,
    };
    ws2.getRow(1).font = {
      name: "Arial",
      family: 4,
      size: 11,
      bold: true,
    };
    ws3.getRow(1).font = {
      name: "Arial",
      family: 4,
      size: 11,
      bold: true,
    };

    const row = ws.addRows(csvData);
    const row2 = ws2.addRows(csvData);
    const row3 = ws3.addRows(csvData);
    const row4 = ws4.addRows(csvData);
    const row5 = ws5.addRows(csvData);
    const buf = workbook.xlsx.writeBuffer();
    workbook.xlsx.writeBuffer().then((buf) => {
      saveAs(new Blob([buf]), `preinscriptions.xlsx`);
    });
  }
  createPreinscription(preinscription) {
    const preinscriptions = [preinscription, ...this.state.preinscriptions];
    this.setState({ preinscriptions });
  }
  toggleCompletedPreinscriptions() {
    this.setState({
      hideCompletedPreinscriptions: !this.state.hideCompletedPreinscriptions,
    });
  }
  addPreinscriptionToState = (preinscription) => {
    this.setState((prevState) => ({
      preinscriptions: [...prevState.preinscriptions.data, preinscription],
    }));
  };
  handleErrors(errorMessage) {
    this.setState({ errorMessage });
  }
  clearErrors() {
    this.setState({
      errorMessage: null,
    });
  }

  render() {
    const element = <FontAwesomeIcon icon={faUpload} />;
    return (
      <>
        {this.state.errorMessage && (
          <ErrorMessage errorMessage={this.state.errorMessage} />
        )}
        {!this.state.isLoading && (
          <>
            <Row xs="1" sm="1" md="5" lg="8">
              <Col>
                {" "}
                <PreinscriptionModal
                  createPreinscription={this.createPreinscription}
                  handleErrors={this.handleErrors}
                  clearErrors={this.clearErrors}
                  getPreinscriptions={this.getPreinscriptions}
                />
              </Col>
              <Col>
                {" "}
                <PreinscriptionLoadModal
                  handleErrors={this.handleErrors}
                  clearErrors={this.clearErrors}
                />
              </Col>
              <Col>
                <Button
                  variant="contained"
                  onClick={(e) =>
                    this.exportExcel(
                      this.state.preinscriptions.data,
                      "preinscriptions"
                    )
                  }
                  style={{
                    background: "#087171",
                    color: "white",
                    boxShadow: "0 1px 2px 1px #087171",
                  }}
                  startIcon={
                    <svg
                      className="bi bi-file-spreadsheet"
                      width="1em"
                      height="1em"
                      viewBox="0 0 16 16"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4 1h8a2 2 0 012 2v10a2 2 0 01-2 2H4a2 2 0 01-2-2V3a2 2 0 012-2zm0 1a1 1 0 00-1 1v10a1 1 0 001 1h8a1 1 0 001-1V3a1 1 0 00-1-1H4z"
                        clipRule="evenodd"
                      />
                      <path
                        fillRule="evenodd"
                        d="M13 6H3V5h10v1zm0 3H3V8h10v1zm0 3H3v-1h10v1z"
                        clipRule="evenodd"
                      />
                      <path
                        fillRule="evenodd"
                        d="M5 14V6h1v8H5zm4 0V6h1v8H9z"
                        clipRule="evenodd"
                      />
                    </svg>
                  }
                >
                  Exporter Données
                </Button>
              </Col>
            </Row>
            <Preinscriptions
              getAutoCompleteResults={this.getAutoCompleteResults}
              term={this.state.term}
              pageId={this.state.pageId}
              preinscriptions={this.state.preinscriptions}
              setPageNumber={this.setPageNumber}
              toggleCompletedPreinscriptions={
                this.toggleCompletedPreinscriptions
              }
              hideCompletedPreinscriptions={
                this.state.hideCompletedPreinscriptions
              }
            >
              {this.state.preinscriptions.data.map((preinscription) => (
                <Preinscription
                  key={preinscription.id}
                  preinscription={preinscription}
                  getPreinscriptions={this.getPreinscriptions}
                  hideCompletedPreinscriptions={
                    this.state.hideCompletedPreinscriptions
                  }
                  handleErrors={this.handleErrors}
                  clearErrors={this.clearErrors}
                />
              ))}
            </Preinscriptions>
          </>
        )}
        {this.state.isLoading && <Spinner />}
      </>
    );
  }
}
document.addEventListener("turbolinks:load", () => {
  const app = document.getElementById("preinscription-partial");
  app && ReactDOM.render(<PreinscrpionPartial />, app);
});
